import axios from "axios";
import { apis } from './params';

export function getCampaignsService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.GET_CAMPAIGNS,
        "data": _body
    })
}

export function getCampaignService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_CAMPAIGN + _param
    })
}

export function getLookupsService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_LOOKUPS + _param
    })
}

export function getBrandsService() {
    return axios({
        "method": "Get",
        "url": apis.GET_BRANDS
    })
}

export function createCampaignService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.CREATE_CAMPAIGN,
        "data": _body
    })
}

export function updateCampaignStatusService(_param: any) {
    return axios({
        "method": "Put",
        "url": apis.UPDATE_CAMPAIGN_STATUS + _param
    })
}

export function getCategoriesService() {
    return axios({
        "method": "Get",
        "url": apis.GET_CATEGORIES
    })
}

export function getBrandsByCategoryService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_BRANDS_BY_CATEGORY + _param
    })
}

export function getSegregatedCategories(_param: string) {
    return axios({
        "method": "Get",
        "url": apis.GET_SEGREGATED_CATEGORIES + _param
    })
}

export function getSegregatedCategoriesInBulk(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.GET_SEGREGATED_CATEGORIES_IN_BULK,
        "data": _body.categoryIdList
    })
}

export function getBrandsByCategoryIds(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.GET_BRANDS_BY_CATEGORYIDS,
        "data": _body
    })
}

export function fetchProductsByCategoryAndBrand(_param: string) {
    return axios({
        "method": "Get",
        "url": apis.FETCH_PRODUCT_BY_BRAND_CATEGORY + _param,
    })
}
