import './Header.scss';
import { IoIosArrowBack } from "react-icons/io"
import UserProfile from './UserProfile';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLayoutJsonByLayoutCode } from '../../../core/actionCreators/lmsActionCreator';
import { toast } from 'react-hot-toast';
import { clearCacheByLayoutService } from '../../../core/services/lmsServices';

const ComponentListHeader = (props: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { layoutId, layoutCode, layoutName,
        device, setAddComponentPopup, setJsonViewPopup } = props

    const selectAndCopyComponentClickHandler = () => {
        navigate(`/layout/${layoutId}/component`)
    }

    const previewLayoutJsonClickHandler = () => {
        dispatch(getLayoutJsonByLayoutCode(`layoutCode=${layoutCode}`, null))
        setJsonViewPopup(true)
    }

    const navigateToClearCacheHandler = () => {
        navigate("/cacheDelete")
    }

    const clearCacheClickHandler = () => {
        let res = window.confirm("Are you sure want to clear cache?")
        if (res) {
            clearCacheByLayoutService(`layoutCode=${layoutCode}`).then((r) => {
                toast.success("Cleared successfully.")
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    return (<>

        <div className="header">
            <div className="header-inner">
                <div className="main-header flex items-center p-[15px]">
                    <div className="header-left">
                    </div>
                    <UserProfile />
                </div>
                <div className="haderVarient2 items-center flex justify-between p-[15px]">
                    <div className="pagename-n-input flex items-center ">
                        <Link to={`/layout`}><IoIosArrowBack /></Link>

                        <div className="pagename-sec">
                            <h2 className='font-bold'>{layoutName}
                            </h2>
                            <p className='onhoverName'>{layoutName}</p>
                            <p className='text-xs p-subname'>{device} | {layoutCode}</p>
                        </div>
                    </div>
                    <div className="right-buttons items-center flex justify-end">
                        <button onClick={previewLayoutJsonClickHandler} className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>LAYOUT JSON</button>
                        <button onClick={selectAndCopyComponentClickHandler} className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Select/Copy Component</button>
                        <button onClick={() => setAddComponentPopup(true)} className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Add Component</button>
                        <button onClick={clearCacheClickHandler} className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>CLEAR CACHE</button>
                        <button onClick={navigateToClearCacheHandler} className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>NAVIGATE TO CLEAR CACHE PAGE</button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ComponentListHeader