import { formatTimestamp } from "../../../utils/helper";

const ServiceabilityListItem = (props: any) => {
    const { data } = props
    return (
        <>
            {data.map((item: any) =>
                <>
                    <tr className='[&:nth-child(odd)]:bg-[#f7f7f7]'>
                        <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{item.msn}</td>
                        <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{item.categoryCode}</td>
                        <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{item.zipcode}</td>
                        <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{JSON.stringify(item.isServiceable)}</td>
                        <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">{JSON.stringify(item.isCod)}</td>
                        <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{formatTimestamp(item.createdAt)}</td>
                        <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{formatTimestamp(item.updatedAt)}</td>
                    </tr>
                </>)}
        </>
    )
}

export default ServiceabilityListItem;