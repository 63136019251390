import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import ServiceabilityList from "../../components/Serviceability/ServiceabilityList";

const Serviceability = () => {
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <ServiceabilityList />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Serviceability;