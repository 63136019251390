import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { apis } from "../../../core/services/params";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const BOOKING_STATUS_COLOR_CODE_MAP: any = {
    NEW: "border border-[#1E90FF] text-[#1E90FF] px-1 py-0.5 rounded",       // Blue for new
    IN_TRANSIT: "border border-[#FFA500] text-[#FFA500] px-1 py-0.5 rounded", // Orange for in transit
    DELIVERED: "border border-[#32CD32] text-[#32CD32] px-1 py-0.5 rounded",  // Green for delivered
    CANCELLED: "border border-[#D9232D] text-[#D9232D] px-1 py-0.5 rounded"   // Red for cancelled
};

enum BookingStatus {
    NEW = "NEW",
    IN_TRANSIT = "IN_TRANSIT",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED"
}

const BOOKING_STATUS = [BookingStatus.NEW, BookingStatus.IN_TRANSIT, BookingStatus.DELIVERED, BookingStatus.CANCELLED]

const MExpress = () => {
    const [data, setData] = useState<any[]>([]);
    const [loader, setLoader] = useState(false);
    const store: any = useSelector((s) => s)
    const { user } = store.authStore

    const searchBookings = async () => {
        try {
            const response = await axios.post(apis.GET_BOOKINGS, {});
            return response.data.data || [];
        } catch (err) {
            console.error("Error fetching bookings:", err);
            return [];
        }
    };

    const updateBookingStatus = async (bookingId: string, status: string) => {
        if (status === "Select") {
            return;
        }
        const payload = {
            bookingId,
            status,
            updatedBy: user.id || -1,
            remarks: ""
        };
        const confirmUpdate = window.confirm(`Are you sure you want to update with bookingId **${bookingId}** with status **${status}**?`);
        if (!confirmUpdate) {
            return;
        }
        if (status === BookingStatus.CANCELLED.toString()) {
            const remarks = window.prompt("Remarks to cancel booking*");
            if (!remarks) {
                toast.error("Since no remarks were entered, the booking update could not be completed.")
                return;
            }
            payload.remarks = remarks;
        }
        try {
            setLoader(true);
            const response = await axios.post(apis.UPDATE_BOOKING_STATUS, payload);
            if (response.data.statusCode === 200) {
                const bookings = await searchBookings();
                setData(bookings);
            }
            toast.success("Updated successfully")
        } catch (error) {
            console.error("Error updating booking status:", error);
        } finally {
            setLoader(false);
        }
    };


    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            setLoader(true)
            const leads = await searchBookings();
            setLoader(false)
            if (isMounted) {
                setData(leads);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    function getNextPossibleBookingStatuses(currentStatus: BookingStatus) {
        if ([BookingStatus.CANCELLED, BookingStatus.DELIVERED].includes(currentStatus)) return [];


        // Find the index of the current status in the array
        const currentIndex = BOOKING_STATUS.indexOf(currentStatus);

        // If the current status is invalid, return an empty array
        if (currentIndex === -1) {
            return [];
        }

        // Return the array of statuses that come after the current status
        return BOOKING_STATUS.slice(currentIndex + 1);
    }

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container bg-white" >
                        {/* <div className="pagelist-filter items-center flex p-[15px]">
                            <div className="form-field">
                                <div className="input-field">
                                    <input
                                        type="text"
                                        placeholder="Search by booking ID, name"
                                    />
                                    <div className="search-icon flex justify-around items-center">
                                        <FiSearch size={16} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-field">
                                <select className="min-w-[126px]">
                                    <option value="true" >Vehicle Type</option>
                                    <option value="true" >MOTO</option>
                                    <option value="false" >TATA ACE</option>
                                </select>
                            </div>
                            <div className="form-field">
                                <select className="min-w-[126px]">
                                    <option>Status</option>
                                    <option>Booked</option>
                                    <option value="true" >Canceled</option>
                                    <option value="false" >Done</option>
                                </select>
                            </div>
                            <div className="form-field">
                                <button className=' py-1 px-4 text-[#D9232D] bg-[#FAE0E1] font-semibold rounded shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Apply</button>
                            </div>
                            <div className="form-field">
                                <div className="flex items-center text-[#288BED]">
                                    <RiDownload2Line size={16} />
                                    <p className="ml-1 text-xs font-semibold">Download</p>
                                </div>
                            </div>
                            <div className="absolute right-4">
                                <button className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Create Page</button>
                            </div>
                        </div> */}
                        <div className="mt-2.5 campaign-list bg-white mb-[54px]">
                            <table className="w-full">
                                <thead className="bg-[#3c3c3c]">
                                    <tr>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Booking ID</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Sender Name & Address</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Pickup Date & Time</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]"> Recipent Name & Address</th>
                                        {/* <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Delivery Date & Time</th> */}
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Vehicle Choice</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Fare</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Distance (km)</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Booked At</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Remarks</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Status</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Action</th>
                                    </tr>
                                </thead>

                                {loader ? <Loader /> : <tbody>
                                    {data.map((item: any, index: any) => (
                                        <tr className="align-top">
                                            <td className="px-4 py-3 text-left text-xs text-[#086AC9]">{item.bookingId}</td>
                                            <td className="px-4 py-3 text-left text-xs text-[#6F6F6F]">
                                                <p className="text-[#086AC9] text-xs">{item.shippingDetails.senderName} {item.shippingDetails.senderPhone}</p>
                                                <p className="text-[10px] ">{item.shippingDetails.senderAddress}</p>
                                            </td>
                                            <td className="px-4 py-3 text-left text-xs text-[#6F6F6F]">
                                                <p className="text-[#3C3C3C]">{item.shippingDetails.pickupDate}</p>
                                                <p className="text-[10px] ">{item.shippingDetails.pickupTime}</p>
                                            </td>
                                            <td className="px-4 py-3 text-left text-xs text-[#6F6F6F]">
                                                <p className="text-[#086AC9] text-xs">{item.shippingDetails.receiverName} {item.shippingDetails.receiverPhone}</p>
                                                <p className="text-[10px] ">{item.shippingDetails.receiverAddress}</p>
                                            </td>
                                            {/* <td className="px-4 py-3 text-left text-xs text-[#6F6F6F]">
                                                <p className="text-[#3C3C3C]">23 Sep 2024</p>
                                                <p className="text-[10px] ">11:30 AM</p>
                                            </td> */}
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item.vehicleType}</td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item.fare.toFixed(2)}</td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item.distance.toFixed(2)}</td>
                                            <td className="px-4 py-3 text-left text-[10px] text-[#3C3C3C]">
                                                {item.shippingDetails.createdAt}
                                            </td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item.remarks}</td>
                                            <td className="px-4 py-3 text-left text-[10px] text-[#3C3C3C]">
                                                {/* <button className="border border-[#0A7205] text-[#0A7205] px-1 py-0.5 rounded">Booked</button> */}
                                                <button className={BOOKING_STATUS_COLOR_CODE_MAP[item.status]}>{item.status}</button>
                                            </td>

                                            {getNextPossibleBookingStatuses(item.status).length > 0 && <td className="px-4 py-3 text-left text-[10px] text-[#3C3C3C]">
                                                <select
                                                    value={item.status}
                                                    className="border border-[#0A7205] text-[#0A7205] px-1 py-0.5 rounded bg-white"
                                                    onChange={(e) => updateBookingStatus(item.bookingId, e.target.value)}
                                                >
                                                    <option>Select</option>
                                                    {
                                                        getNextPossibleBookingStatuses(item.status).map((status) => (
                                                            <option key={status} value={status}>
                                                                {status}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </td>}
                                        </tr>
                                    ))
                                    }
                                </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default MExpress;