export const PAGE_SIZE = 14;
export const GET_CAMPAIGN_DEFAULT_PAGE_SIZE = 18
export const GET_BRAND_BLOCK_DEFAULT_PAGE_SIZE = 16
export const GET_CATEGORY_BLOCK_DEFAULT_PAGE_SIZE = 16
export const GET_GENERAL_BLOCK_DEFAULT_PAGE_SIZE = 16
export const GET_TEMPLATE_BLOCK_DEFAULT_PAGE_SIZE = 16
export const GET_STATIC_HTML_BLOCK_DEFAULT_PAGE_SIZE = 16
export const GET_IMAGE_BLOCK_DEFAULT_PAGE_SIZE = 16
export const GET_PRODUCT_BLOCK_DEFAULT_PAGE_SIZE = 16
// export const GET_STATIC_HTML_BLOCK_DEFAULT_PAGE_SIZE = 16
export const GET_FBTS_DEFAULT_PAGE_SIZE = 17
export const GET_FAQ_DEFAULT_PAGE_SIZE = 16
export const GET_PLA_FEED_DEFAULT_SIZE = 16
export const GET_ATTRIBUTE_DEFAULT_PAGE_SIZE = 10
export const GET_QNA_DEFAULT_SIZE = 8;
export const GET_SERVICEABILITY_DEFAULT_SIZE = 15;
export const GET_REVIEWS_DEFAULT_SIZE = 10;
export const GET_AI_DEFAULT_PAGE_SIZE = 5;
export const GET_PAGEMAPPING_DEFAULT_PAGE_SIZE = 10
export const GET_ARTICLE_PAGE_SIZE = 10


export const CAMPAIGN_SECTION_TYPES = {
    "BANNER_AD_UNIT": "BANNER_AD_UNIT",
    "FEATURED_PRODUCT_UNIT": "FEATURED_PRODUCT_UNIT",
    "PROMOTED_BRAND_UNIT": "PROMOTED_BRAND_UNIT",
    "VIDEO_UNIT": "VIDEO_UNIT"
}
export const CAMPAIGN_TYPES = {
    "BRAND_BASED_CAMPAIGN": "BRAND_BASED_CAMPAIGN",
    "CATEGORY_BASED_CAMPAIGN": "CATEGORY_BASED_CAMPAIGN",
    "BRAND_CATEGORY_BASED_CAMPAIGN": "BRAND_CATEGORY_BASED_CAMPAIGN",
    "MSNS_BASED_CAMPAIGN": "MSNS_BASED_CAMPAIGN",
    "ORDER_CONFIRMATION_PAGE_CAMPAIGN": "ORDER_CONFIRMATION_PAGE_CAMPAIGN",
    "PRODUCT_BASED_CAMPAIGN": "PRODUCT_BASED_CAMPAIGN",
    "HOME_PAGE_ONSCROLL_ADUNIT": "HOME_PAGE_ONSCROLL_ADUNIT"
}

export const CREATE_CAMPAIGN_FORM_ERRORS = {
    "INVALID_FILE_DIMENSION": "Invalid Dimension, Kindly provide a banner of size ",
    "INVALID_FILE_SIZE": "Invalid Size, Kindly provide a banner of size less than ",
    "INVALID_LANDING_PAGE_URL_STAGING": "Invalid url, kindly provide a url which starts with https://",
    "INVALID_LANDING_PAGE_URL_PRODUCTION": "Invalid url, kindly provide a url which starts with https://",
    "INVALID_YOUTUBE_URL": "Invalid url, Kindly provide valid youtube url",
    "NOT_ABLE_TO_SAVE_CAMPAIGN_KINDLY_CHECK_FOR_ERRORS": "Not able to save campaign, Kindly check for errors",
    "PLEASE_SELECT_PLATFORM": "Please select platform",
    "PLEASE_ADD_CAMPAIGN_NAME": "Please add campaign name",
    "PLEASE_SELECT_START_DATE": "Please select start date",
    "PLEASE_SELECT_END_DATE": "Please select end date",
    "PLEASE_FILL_CURRENT_BANNER_DETAILS": "Please fill current banner details"
}

export const CAMPAIGN_FILE_DIMENSION_CONFIG: any = {
    PWA: {
        "INLINE_RECTANGLE_THANKYOUPAGE_POSITION_1": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_THANKYOUPAGE_POSITION_2": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_THANKYOUPAGE_POSITION_3": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_PLP_POSITION_1": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_PLP_POSITION_2": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_PLP_POSITION_3": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "MOBILE_LEADERBOARD_PLP_POSITION_1": {
            allowedDimensions: ["320 * 50"],
            maxFileSizeInKb: 500
        },
        "MOBILE_LEADERBOARD_PLP_POSITION_2": {
            allowedDimensions: ["320 * 50"],
            maxFileSizeInKb: 500
        },
        "MOBILE_LEADERBOARD_PLP_POSITION_3": {
            allowedDimensions: ["320 * 50"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_1": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_2": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_3": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_4": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_5": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_PDP": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "MOBILE_LEADERBOARD_PDP": {
            allowedDimensions: ["320 * 50"],
            maxFileSizeInKb: 500
        },
        "PROMOTED_BRAND_UNIT": {
            allowedDimensions: ["125 * 125"],
            maxFileSizeInKb: 500
        }
    },
    DESKTOP: {
        "DESKTOP_INLINE_RECTANGLE_THANKYOUPAGE_POSITION_1": {
            allowedDimensions: ["336 * 280", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "DESKTOP_INLINE_RECTANGLE_THANKYOUPAGE_POSITION_2": {
            allowedDimensions: ["336 * 280", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "DESKTOP_INLINE_RECTANGLE_THANKYOUPAGE_POSITION_3": {
            allowedDimensions: ["336 * 280", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "INLINE_RECTANGLE_PLP": {
            allowedDimensions: ["336 * 280", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "DESKTOP_LEADERBOARD_PLP_POSITION_1": {
            allowedDimensions: ["728 * 90", "970 * 90", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "DESKTOP_LEADERBOARD_PLP_POSITION_2": {
            allowedDimensions: ["728 * 90", "970 * 90", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "DESKTOP_LEADERBOARD_PDP": {
            allowedDimensions: ["728 * 90", "970 * 90", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "TOP_BANNER_POSITION_1": {
            allowedDimensions: ["1079 * 230", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "TOP_BANNER_POSITION_2": {
            allowedDimensions: ["1079 * 230", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "TOP_BANNER_POSITION_3": {
            allowedDimensions: ["1079 * 230", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "TOP_BANNER_POSITION_4": {
            allowedDimensions: ["1079 * 230", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "TOP_BANNER_POSITION_5": {
            allowedDimensions: ["1079 * 230", "1500 * 230"],
            maxFileSizeInKb: 600
        },
        "PROMOTED_BRAND_UNIT": {
            allowedDimensions: ["264 * 186", "1500 * 230"],
            maxFileSizeInKb: 500
        }
    },
    APP: {
        "INLINE_RECTANGLE_THANKYOUPAGE_POSITION_1": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_THANKYOUPAGE_POSITION_2": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_THANKYOUPAGE_POSITION_3": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_PLP_POSITION_1": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_PLP_POSITION_2": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_PLP_POSITION_3": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "MOBILE_LEADERBOARD_PLP_POSITION_1": {
            allowedDimensions: ["320 * 50"],
            maxFileSizeInKb: 500
        },
        "MOBILE_LEADERBOARD_PLP_POSITION_2": {
            allowedDimensions: ["320 * 50"],
            maxFileSizeInKb: 500
        },
        "MOBILE_LEADERBOARD_PLP_POSITION_3": {
            allowedDimensions: ["320 * 50"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_1": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_2": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_3": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_4": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "TOP_BANNER_POSITION_5": {
            allowedDimensions: ["375 * 180"],
            maxFileSizeInKb: 500
        },
        "INLINE_RECTANGLE_PDP": {
            allowedDimensions: ["300 * 250"],
            maxFileSizeInKb: 500
        },
        "MOBILE_LEADERBOARD_PDP": {
            allowedDimensions: ["320 * 50"],
            maxFileSizeInKb: 500
        },
        "PROMOTED_BRAND_UNIT": {
            allowedDimensions: ["125 * 125"],
            maxFileSizeInKb: 500
        }
    }
}

export const PLATFORMS = [
    {
        "title": "MOBILE APP",
        "value": "app"
    },
    {
        "title": "PWA",
        "value": "pwa"
    },
    {
        "title": "DESKTOP",
        "value": "desktop"
    }]

export const CAMPAIGN_FILE_DIMENSION_CONFIG_UI = {
    "PWA": [{
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "inline_rectangle_plp_position_1",
        "dimension": ["300 * 250"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "mobile_leaderboard_plp_position_1",
        "dimension": ["320 * 50"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "inline_rectangle_plp_position_2",
        "dimension": ["300 * 250"]
    }, {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "mobile_leaderboard_plp_position_2",
        "dimension": ["320 * 50"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "inline_rectangle_plp_position_3",
        "dimension": ["300 * 250"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "mobile_leaderboard_plp_position_3",
        "dimension": ["320 * 50"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "top_banner_<position no.>",
        "dimension": ["375 * 180"]
    },
    {
        "pageType": "PDP",
        "bannerName": "inline_rectangle_pdp",
        "dimension": ["300 * 250"]
    },
    {
        "pageType": "PDP",
        "bannerName": "mobile_leaderboard_pdp",
        "dimension": ["320 * 50"]
    }],
    "DESKTOP": [{
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "inline_rectangle_plp",
        "dimension": ["336 * 280", "1500 * 230"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "desktop_leaderboard_plp_position_1",
        "dimension": ["728 * 90", "970 * 90", "1500 * 230"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "desktop_leaderboard_plp_position_2",
        "dimension": ["728 * 90", "970 * 90", "1500 * 230"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "top_banner_<position no.>",
        "dimension": ["1079 * 230", "1500 * 230"]
    },
    {
        "pageType": "PDP",
        "bannerName": "desktop_leaderboard_pdp",
        "dimension": ["728 * 90", "970 * 90", "1500 * 230"]
    }],
    "APP": [{
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "inline_rectangle_plp_position_1",
        "dimension": ["300 * 250"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "mobile_leaderboard_plp_position_1",
        "dimension": ["320 * 50"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "inline_rectangle_plp_position_2",
        "dimension": ["300 * 250"]
    }, {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "mobile_leaderboard_plp_position_2",
        "dimension": ["320 * 50"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "inline_rectangle_plp_position_3",
        "dimension": ["300 * 250"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "mobile_leaderboard_plp_position_3",
        "dimension": ["320 * 50"]
    },
    {
        "pageType": "Brand+Brand Category+PLP",
        "bannerName": "top_banner_<position no.>",
        "dimension": ["375 * 180"]
    },
    {
        "pageType": "PDP",
        "bannerName": "inline_rectangle_pdp",
        "dimension": ["300 * 250"]
    },
    {
        "pageType": "PDP",
        "bannerName": "mobile_leaderboard_pdp",
        "dimension": ["320 * 50"]
    }]
}

export const WHATSAPP_CAMPAIGN_TYPES = {
    "DELAY_COMMUNICATION": "DELAY_COMMUNICATION"
}

export const AI_POWERED_TYPES = {
    "GENERATE_PRODUCT_DESCRIPTION": "GENERATE_PRODUCT_DESCRIPTION"
}

export const USER_ROLES = {
    MASTER_ADMIN: 1, //role id
    READ_USER: 2
}

export const LAYOUT_PAGE_HEADERS = [
    'Layout Name',
    'Platform',
    'Created by',
    'Creation date & time',
    'Status',
    'Action'
];

export const REVIEW_PAGE_HEADERS = [
    'ID',
    'MSN',
    'User',
    'Rating',
    'Review Subject',
    'Review Text',
    'Enabled',
    'Approved',
    // 'Images',
    // 'Videos',
    'Created At Modified At',
    'Action'
]

export const SERVICEABLITY_PAGE_HEADERS = [
    'Msn',
    'Category',
    'ZipCode',
    'Serviceable',
    'Cod',
    'Created At',
    'Updated At'
];

export const PAGE_MODES = {
    "VIEW": "VIEW",
    "CREATE": "CREATE",
    "EDIT": "EDIT"
}
