import axios from "axios";
import { apis } from './params';

export function getLayoutsService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_LAYOUTS + _param
    })
}

export function createLayoutService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.CREATE_LAYOUT,
        "data": _body
    })
}

export function deleteLayoutService(_param: any) {
    return axios({
        "method": "Delete",
        "url": apis.DELETE_LAYOUT + _param
    })
}

export function getLayoutService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_LAYOUT + _param
    })
}

export function getStaticFormFieldsService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_STATIC_FORM_FIELDS
    })
}

export function createComponentService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.CREATE_COMPONENT_V2,
        "data": _body
    })
}

export function getComponentService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_COMPONENT_BY_ID + _param
    })
}

export function getPopulatedLayoutService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_POPULATED_LAYOUT + _param
    })
}

export function updateComponentService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.UPDATE_COMPONENT,
        "data": _body
    })
}

export function updateComponentStatusService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.UPDATE_COMPONENT_STATUS,
        "data": _body
    })
}

export function getComponentsService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_COMPONENTS + _param
    })
}

export function copyComponentService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.COPY_COMPONENT,
        "data": _body
    })
}

export function getLayoutJsonByLayoutCode(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.SET_LAYOUT_JSON_BY_LAYOUT_CODE + _param
    })
}

export function deleteComponentService(_param: any) {
    return axios({
        "method": "Delete",
        "url": apis.DELETE_COMPONENT + _param
    })
}

export function getPopulatedComponentService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_POPULATED_COMPONENT + _param
    })
}

export function createElementService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.CREATE_ELEMENT,
        "data": _body
    })
}

export function updateElementStatusService(_body: any) {
    return axios({
        "method": "Put",
        "url": apis.UPDATE_ELEMENT_STATUS,
        "data": _body
    })
}

export function updateElementService(_body: any) {
    return axios({
        "method": "Put",
        "url": apis.UPDATE_ELEMENT,
        "data": _body
    })
}

export function deleteElementService(_param: any) {
    return axios({
        "method": "Delete",
        "url": apis.DELETE_ELEMENT + _param
    })
}

export function clearCacheService(_param: any) {
    return axios({
        "method": "Delete",
        "url": apis.CLEAR_CACHE + _param
    })
}


export function clearCacheByLayoutService(_param: any) {
    return axios({
        "method": "Delete",
        "url": apis.CLEAR_CACHE_BY_LAYOUT + _param
    })
}
