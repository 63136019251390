import { useEffect, useState } from "react";
import { getBrandsByCategoryIds, getBrandsService } from "../../../core/services/adsenseServices";
import { CAMPAIGN_TYPES } from "../../../utils/constants";

interface Brand {
    brandId: string;
    brandName: string;
    link: string;
    active: boolean;
    country: string;
    countryCode: string;
    brandSearchString: string;
    brandLogo: string;
    productCount: number;
}

const MultiBrandSelection = (props: any) => {
    const { campaign, setcampaign, pageMode } = props
    const [brands, setBrands] = useState<Brand[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
    const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
    const [areAllBrandsSelected, setAreAllBrandsSelected] = useState<boolean>(false)

    useEffect(() => {
        if ([CAMPAIGN_TYPES.BRAND_CATEGORY_BASED_CAMPAIGN, CAMPAIGN_TYPES.PRODUCT_BASED_CAMPAIGN].includes(campaign.campaignType)) {
            const { l1Categories, l2Categories, l3Categories } = campaign.segregatedCategories;
            let aggregatedCategories = [
                ...l1Categories.map((c: any) => c.categoryCode),
                ...l2Categories.map((c: any) => c.categoryCode),
                ...l3Categories.map((c: any) => c.categoryCode)
            ];

            //Patch || PRODUCT BASED CAMPAIGN, applicable only for l2
            aggregatedCategories = [...l2Categories.map((c: any) => c.categoryCode)]

            // dispatch(setAdsenseLoader(true))
            getBrandsByCategoryIds(aggregatedCategories)
                .then((data: any) => {
                    // dispatch(setAdsenseLoader(false))
                    data = data.data
                    let _brands: any = Object.values(data).flat();
                    _brands.sort((a: any, b: any) => {
                        if (a.brandName < b.brandName) {
                            return -1;
                        }
                        if (a.brandName > b.brandName) {
                            return 1;
                        }
                        return 0;
                    });
                    setBrands([..._brands])
                    setFilteredBrands([..._brands])
                })
                .catch((err) => {
                    // dispatch(setAdsenseLoader(false))
                    console.error(err);
                });
        }
    }, [campaign._multiCategorylastUpdatedAt]);

    useEffect(() => {
        if (campaign.campaignType === CAMPAIGN_TYPES.BRAND_BASED_CAMPAIGN) {
            // dispatch(setAdsenseLoader(true))
            getBrandsService().then((data: any) => {
                // dispatch(setAdsenseLoader(false))
                data = data.data.brands
                let _brands = data.map((b: any) => ({
                    brandId: b["id"],
                    brandName: b["name"]
                }));
                _brands.sort((a: any, b: any) => {
                    if (a.brandName < b.brandName) {
                        return -1;
                    }
                    if (a.brandName > b.brandName) {
                        return 1;
                    }
                    return 0;
                });
                setBrands([..._brands])
                setFilteredBrands([..._brands])
            }).catch((err) => {
                // dispatch(setAdsenseLoader(false))
                console.error(err);
            })
        }
    }, [campaign.campaignType])

    useEffect(() => {
        if (["EDIT", "VIEW"].includes(pageMode) && campaign.segregatedBrands) {
            setSelectedBrands(campaign.segregatedBrands)
        }
    }, [pageMode])

    useEffect(() => {
        if (brands && Array.isArray(brands) && brands.length) {
            setFilteredBrands(
                brands.filter((brand: any) =>
                    brand.brandName.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
    }, [searchTerm]);

    const handleCheckboxChange = (brand: any) => {
        const isSelected = selectedBrands.some((selected: any) => selected.brandId === brand.brandId);
        const newSelectedBrands: any = isSelected
            ? selectedBrands.filter((selected: any) => selected.brandId !== brand.brandId)
            : [...selectedBrands, brand];
        setSelectedBrands(newSelectedBrands);
        if (newSelectedBrands.length) {
            setcampaign({
                ...campaign,
                segregatedBrands: newSelectedBrands,
                hasSiblings: true
            })
        }
    };

    const selectAllBrandHandler = () => {
        setSelectedBrands(areAllBrandsSelected ? campaign.segregatedBrands : filteredBrands);
        setAreAllBrandsSelected(prevState => !prevState);
    }

    return (<>
        {campaign?.isUniversal?.toString() === "false" && (
            <div className="flex flex-col w-full mb-7 last:mb-0">
                <div className="flex items-center mb-4">
                    <p className="text-xs font-semibold text-[#303030] w-48">Select Brand Name ({brands.length}):</p>
                    <div className="w-2/5">
                        <div className="relative border border-gray-300 rounded-lg p-3">
                            <div className="relative">
                                <div className="form-field full-width">
                                    <div className='flex items-baseline'>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="mb-2 p-2 mr-4 border border-gray-300 rounded"
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <button type="button" className='bg-[#efefe4] border border-solid border-[#c4c4c4] py-1 px-2.5 rounded' onClick={() => selectAllBrandHandler()}>Select All</button>
                                    </div>
                                    <div className="h-32 p-2 border border-gray-300 rounded overflow-auto">
                                        {filteredBrands.map((d: any, index: number) => (
                                            <div key={index} className="flex items-center mb-2">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedBrands.some((selected: any) => selected.brandId === d.brandId)}
                                                    onChange={() => handleCheckboxChange(d)}
                                                    className="mr-2"
                                                />
                                                <label>{d.brandName}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <p className="text-xs font-semibold text-[#303030] w-48">Selected Brands:</p>
                    <div className="w-full">
                        <div className="relative">
                            <div className="flex flex-wrap mt-2">
                                {selectedBrands.map((brand: any, index) => (
                                    <div
                                        key={index}
                                        className="bg-gray-200 text-gray-800 px-2 py-1 m-1 rounded"
                                    >
                                        {brand.brandName}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>)
}

export default MultiBrandSelection