import queryString from "query-string";
import { useCallback, useEffect, useRef, useState } from "react";
import { getServiceabilityList, uploadServiceabilityFile } from "../../../core/actionCreators/servicebilityActionCreators";
import { GET_SERVICEABILITY_DEFAULT_SIZE } from "../../../utils/constants";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import ServiceabilityListItem from "./ServiceabilityListItem";
import { useDispatch } from "react-redux";
import { convertJsonToCsv, isEqual, removeEmptyStringKeys } from "../../../utils/helper";
import CommonPagination from "../CommonPagination/CommonPagination";


const ServiceabilityList = () => {
    const dispatch = useDispatch()

    const { serviceabilityData, isLoading } = useSelector((s: any) => s.serviceabilityStore)

    const { user } = useSelector((s: any) => s.authStore)

    const [file, setFile] = useState("")
    const [searchCriteria, setSearchCriteria] = useState({
        msn: "",
        categoryCode: "",
        isCod: "",
        isServiceable: "",
        pageNumber: 0,
        pageSize: GET_SERVICEABILITY_DEFAULT_SIZE,
        zipcode: "",
    } as any)

    const previousSearchCriteriaRef = useRef(searchCriteria);

    const debounceTimeoutRef = useRef<any>(null);

    const debouncedSearch = useCallback((updatedSearchCriteria: any) => {
        // Clear the previous debounce timeout
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        // Set a new debounce timeout
        debounceTimeoutRef.current = setTimeout(() => {
            const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
            const previousCriteria = previousSearchCriteriaRef.current;
            const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

            if (searchFilterChanged) {
                const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria);
                for (let key in parsedSearchCriteria) {
                    if (["msn", "categoryCode", "isCod", "isServicable", "zipcode"].includes(key)) {
                        parsedSearchCriteria.pageNumber = 0;
                        break;
                    }
                }
                dispatch(getServiceabilityList(queryString.stringify(parsedSearchCriteria), null));
                previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
            }
        }, 600); // Delay of 600ms for debouncing (you can adjust this)
    }, [dispatch]);

    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        debouncedSearch(updatedSearchCriteria);
    }, [searchCriteria, debouncedSearch]);


    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }

    const fileHandler = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            setFile(file)
        }
    }

    const bulkUploadHandler = () => {
        if (!file) {
            toast.error("Please select file.")
            return
        }
        const formData = new FormData()
        formData.append("file", file)
        dispatch(uploadServiceabilityFile({
            param: `email=${user.email || "deepak.singh1@moglix.com"}`,
            data: formData
        }, (d: any) => {
            toast.success("Uploaded successfully.")
            // window.location.reload()
        }))
    }

    const downloadServiceabilityFile = () => {
        const data = [
            { msn: 'MSN2RCODZ5NY9R', zipcode: "201301", isCod: "FALSE", categoryCode: "-1", isServiceable: "TRUE" }
        ];
        convertJsonToCsv(data, 'sample.csv')
    }

    return (
        <>
            <div className="p-4 flex">
                <p className="text-xl font-bold text-[#43425D] w-1/5">SERVICEABILITY</p>
                <div className="flex w-4/5 ml-auto justify-end">
                    {/* <div className="flex items-center h-9 mr-4 w-[120px]">
                        <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            value={"true"}
                        />
                        <span className="ml-2 text-[#3c3c3c] text-sm font-medium">
                            MSN
                        </span>
                    </div>
                    <div className="flex items-center h-9 mr-4 w-[160px]">
                        <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            value={"true"}
                        />
                        <span className="ml-2 text-[#3c3c3c] text-sm font-medium">
                            CATEGORY
                        </span>
                    </div> */}
                    <button onClick={downloadServiceabilityFile} className="text-[10px] text-[#3c3c3c]">Download Sample File</button>
                    <input onChange={(e) => fileHandler(e)} type="file" className="border border-gray-300 py-2 rounded focus: outline-none px-1.5 text-xs w-1/4" />
                    <button onClick={bulkUploadHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-5">Bulk Upload</button>
                </div>
            </div>
            <div className="mt-2.5 campaign-list bg-white mb-[54px]">
                <table className="w-full">
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">Msn</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">Category</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">ZipCode</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">Serviceable</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Cod</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">Created At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">Updated At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                                <input type="text" value={searchCriteria.msn} onChange={(e) => setSearchCriteria({ ...searchCriteria, msn: e.target.value })} className="border border-gray-300 h-7 rounded-sm focus:outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                                <input type="text" value={searchCriteria.categoryCode} onChange={(e) => setSearchCriteria({ ...searchCriteria, categoryCode: e.target.value })} className="border border-gray-300 h-7 rounded-sm focus:outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                                <input type="text" value={searchCriteria.zipcode} onChange={(e) => setSearchCriteria({ ...searchCriteria, zipcode: e.target.value })} className="border border-gray-300 h-7 rounded-sm focus: outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                                <select value={searchCriteria.isApproved} onChange={(e) => setSearchCriteria({ ...searchCriteria, isServiceable: e.target.value })} className="bg-white border border-gray-300 h-7 w-full focus:outline-none px-1.5 rounded-sm">
                                    <option value=''>All</option>
                                    <option value='true'>True</option>
                                    <option value='false'>False</option>
                                </select>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">
                                <select value={searchCriteria.isApproved} onChange={(e) => setSearchCriteria({ ...searchCriteria, isCod: e.target.value })} className="bg-white border border-gray-300 h-7 w-full focus:outline-none px-1.5 rounded-sm">
                                    <option value=''>All</option>
                                    <option value='true'>True</option>
                                    <option value='false'>False</option>
                                </select>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                            </td>
                        </tr>
                        {isLoading ? <Loader /> : <ServiceabilityListItem data={serviceabilityData?.data || []} />}
                    </tbody>
                </table>
            </div>
            {serviceabilityData?.data && <CommonPagination data={serviceabilityData} PAGE_SIZE={GET_SERVICEABILITY_DEFAULT_SIZE} onPageChange={onPageChange} />}
        </>
    )
}
export default ServiceabilityList;