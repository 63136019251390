import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCategories } from '../../../core/actionCreators/adsenseActionCreator';
import { getSegregatedCategories, getSegregatedCategoriesInBulk } from '../../../core/services/adsenseServices';
import { useSelector } from 'react-redux';
import { CAMPAIGN_TYPES } from '../../../utils/constants';

interface Category {
    categoryCode: string;
    categoryName: string;
}

const MultiCategorySelection = (props: any) => {
    const { campaign, setcampaign, pageMode } = props
    const dispatch = useDispatch()

    let { categories } = useSelector((s: any) => s.adsenseStore);
    const [CATEGORY_CODES, SET_CATEGORY_CODES] = useState<{ L1: any[], L2: any[], L3: any[] }>({
        L1: [],
        L2: [],
        L3: []
    });

    const [l1SearchTerm, setL1SearchTerm] = useState('');
    const [l2SearchTerm, setL2SearchTerm] = useState('');
    const [l3SearchTerm, setL3SearchTerm] = useState('')

    const [l1Categories, setL1Categories] = useState([]);
    const [l2Categories, setL2Categories] = useState([]);
    const [l3Categories, setL3Categories] = useState([]);

    const [filteredL1Categories, setFilteredL1Categories] = useState<Category[]>([]);
    const [filteredL2Categories, setFilteredL2Categories] = useState<Category[]>([]);
    const [filteredL3Categories, setFilteredL3Categories] = useState<Category[]>([]);

    const [selectedL1Categories, setSelectedL1Categories] = useState<Category[]>([])
    const [selectedL2Categories, setSelectedL2Categories] = useState<Category[]>([])
    const [selectedL3Categories, setSelectedL3Categories] = useState<Category[]>([])

    const [areAllL1CategoriesSelected, setAreAllL1CategoriesSelected] = useState<boolean>(false)
    const [areAllL2CategoriesSelected, setAreAllL2CategoriesSelected] = useState<boolean>(false)
    const [areAllL3CategoriesSelected, setAreAllL3CategoriesSelected] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getCategories(null, null))
        const cachedCategoryCodes = localStorage.getItem('CATEGORY_CODES');
        if (cachedCategoryCodes) {
            SET_CATEGORY_CODES(JSON.parse(cachedCategoryCodes));
        }
        else {
            const promises = [
                getSegregatedCategories(`type=l1`),
                getSegregatedCategories(`type=l2`),
                getSegregatedCategories(`type=l3`)
            ];
            // dispatch(setAdsenseLoader(true))
            Promise.all(promises).then((data) => {
                // dispatch(setAdsenseLoader(false))
                const l1Categories = data[0].data["categories"].map((c: any) => c["categoryId"])
                const l2Categories = data[1].data["categories"].map((c: any) => c["categoryId"])
                const l3Categories = data[2].data["categories"].map((c: any) => c["categoryId"])
                SET_CATEGORY_CODES({
                    L1: l1Categories,
                    L2: l2Categories,
                    L3: l3Categories
                })
                localStorage.setItem('CATEGORY_CODES', JSON.stringify({
                    L1: l1Categories,
                    L2: l2Categories,
                    L3: l3Categories
                }));
            }).catch((err) => {
                // dispatch(setAdsenseLoader(false))
                console.log(err)
            })
        }
    }, [])

    useEffect(() => {
        if (["EDIT", "VIEW"].includes(pageMode) && campaign?.segregatedCategories) {
            setSelectedL1Categories(campaign.segregatedCategories.l1Categories || []);
            setSelectedL2Categories(campaign.segregatedCategories.l2Categories || []);
            setSelectedL3Categories(campaign.segregatedCategories.l3Categories || []);

            const refreshHandler = async () => {
                // dispatch(setAdsenseLoader(true))
                await refreshL2Handler(campaign.segregatedCategories.l1Categories);
                await refreshL3Handler(campaign.segregatedCategories.l2Categories);
                // dispatch(setAdsenseLoader(false))
            };

            setTimeout(() => {
                refreshHandler();
            }, 100)
        }
    }, [pageMode, campaign.segregatedCategories]);


    useEffect(() => {
        if (categories.length) {
            const l1Categories: any = []
            const l2Categories: any = []
            const l3Categories: any = []
            for (let category of categories) {
                if (CATEGORY_CODES.L1.includes(category?.categoryCode)) {
                    l1Categories.push(category)
                }
                if (CATEGORY_CODES.L2.includes(category.categoryCode)) {
                    l2Categories.push(category)
                }
                if (CATEGORY_CODES.L3.includes(category.categoryCode)) {
                    l3Categories.push(category)
                }
            }

            setL1Categories(l1Categories)
            setL2Categories(l2Categories)
            setL3Categories(l3Categories)
        }
    }, [categories, CATEGORY_CODES])

    useEffect(() => {
        const searchTerm = l1SearchTerm.toLowerCase();
        const startsWith = l1Categories.filter((category: any) =>
            category.categoryName.toLowerCase().startsWith(searchTerm)
        );
        const includes = l1Categories.filter((category: any) =>
            !category.categoryName.toLowerCase().startsWith(searchTerm) &&
            category.categoryName.toLowerCase().includes(searchTerm)
        );

        setFilteredL1Categories([...startsWith, ...includes]);
    }, [l1SearchTerm, l1Categories]);

    useEffect(() => {
        const searchTerm = l2SearchTerm.toLowerCase();
        const startsWith = l2Categories.filter((category: any) =>
            category.categoryName.toLowerCase().startsWith(searchTerm)
        );
        const includes = l2Categories.filter((category: any) =>
            !category.categoryName.toLowerCase().startsWith(searchTerm) &&
            category.categoryName.toLowerCase().includes(searchTerm)
        );

        setFilteredL2Categories([...startsWith, ...includes]);
    }, [l2SearchTerm, l2Categories]);

    useEffect(() => {
        const searchTerm = l3SearchTerm.toLowerCase();
        const startsWith = l3Categories.filter((category: any) =>
            category.categoryName.toLowerCase().startsWith(searchTerm)
        );
        const includes = l3Categories.filter((category: any) =>
            !category.categoryName.toLowerCase().startsWith(searchTerm) &&
            category.categoryName.toLowerCase().includes(searchTerm)
        );

        setFilteredL3Categories([...startsWith, ...includes]);
    }, [l3SearchTerm, l3Categories]);

    const handleCheckboxChangeForL1Category = (category: any) => {
        const isSelected = selectedL1Categories.some((selected: any) => selected.categoryCode === category.categoryCode);
        const newSelectedCategories: any = isSelected
            ? selectedL1Categories.filter((selected: any) => selected.categoryCode !== category.categoryCode)
            : [...selectedL1Categories, category];

        setSelectedL1Categories(newSelectedCategories);
        campaign["segregatedCategories"]["l1Categories"] = newSelectedCategories
        campaign["hasSiblings"] = true
        campaign['_multiCategorylastUpdatedAt'] = new Date()
        setcampaign({ ...campaign })
    };

    const handleCheckboxChangeForL2Category = (category: any) => {
        const isSelected = selectedL2Categories.some((selected: any) => selected.categoryCode === category.categoryCode);
        const newSelectedCategories: any = isSelected
            ? selectedL2Categories.filter((selected: any) => selected.categoryCode !== category.categoryCode)
            : [...selectedL2Categories, category];

        setSelectedL2Categories(newSelectedCategories);
        campaign["segregatedCategories"]["l2Categories"] = newSelectedCategories
        campaign["hasSiblings"] = true
        campaign['_multiCategorylastUpdatedAt'] = new Date()
        setcampaign({ ...campaign })
    };

    const handleCheckboxChangeForL3Category = (category: any) => {
        const isSelected = selectedL3Categories.some((selected: any) => selected.categoryCode === category.categoryCode);
        const newSelectedCategories: any = isSelected
            ? selectedL3Categories.filter((selected: any) => selected.categoryCode !== category.categoryCode)
            : [...selectedL3Categories, category];

        setSelectedL3Categories(newSelectedCategories);
        campaign["segregatedCategories"]["l3Categories"] = newSelectedCategories
        campaign["hasSiblings"] = true
        campaign['_multiCategorylastUpdatedAt'] = new Date()
        setcampaign({ ...campaign })
    };

    const refreshL2Handler = async (selectedL1Categories: Category[]) => {
        try {
            const _categoryIdList = selectedL1Categories.map((element) => element.categoryCode)
            if (_categoryIdList.length) {
                // dispatch(setAdsenseLoader(true))
                const { data } = await getSegregatedCategoriesInBulk({
                    categoryIdList: selectedL1Categories.map((element) => element.categoryCode)
                })
                // dispatch(setAdsenseLoader(false))
                if (data.categories) {
                    const mappedCategories = data.categories.map((c: any) => ({
                        categoryName: c.categoryName,
                        categoryCode: c.categoryId
                    }));
                    setFilteredL2Categories(mappedCategories);
                }
            }
            else {
                setFilteredL2Categories(
                    l2Categories.filter((category: any) =>
                        category.categoryName.toLowerCase().includes(l2SearchTerm.toLowerCase())
                    )
                );
            }
        } catch (err) {
            // dispatch(setAdsenseLoader(false))
            console.error("Error fetching segregated categories:", err);
        }
    };

    const refreshL3Handler = async (selectedL2Categories: Category[]) => {
        try {
            const _categoryIdList = selectedL2Categories.map((element) => element.categoryCode)
            if (_categoryIdList.length) {
                // dispatch(setAdsenseLoader(true))
                const { data } = await getSegregatedCategoriesInBulk({
                    categoryIdList: selectedL2Categories.map((element) => element.categoryCode)
                })
                // dispatch(setAdsenseLoader(false))
                if (data.categories) {
                    const mappedCategories = data.categories.map((c: any) => ({
                        categoryName: c.categoryName,
                        categoryCode: c.categoryId
                    }));
                    setFilteredL3Categories(mappedCategories)
                }

            }
            else {
                setFilteredL3Categories(
                    l3Categories.filter((category: any) =>
                        category.categoryName.toLowerCase().includes(l3SearchTerm.toLowerCase())
                    )
                );
            }
        } catch (err) {
            // dispatch(setAdsenseLoader(false))
            console.error("Error fetching segregated categories:", err);
        }
    }

    const selectAllL1Handler = () => {
        let newSelectedCategories = areAllL1CategoriesSelected ? campaign.segregatedCategories.l1Categories : filteredL1Categories
        setSelectedL1Categories(newSelectedCategories);
        setAreAllL1CategoriesSelected(prevState => !prevState);
        campaign["segregatedCategories"]["_l1Categories"] = newSelectedCategories
        campaign['_multiCategorylastUpdatedAt'] = new Date()
        setcampaign({ ...campaign })
    }

    const selectAllL2Handler = () => {
        let newSelectedCategories = areAllL2CategoriesSelected ? campaign.segregatedCategories.l2Categories : filteredL2Categories
        setSelectedL2Categories(newSelectedCategories);
        setAreAllL2CategoriesSelected(prevState => !prevState);
        campaign["segregatedCategories"]["_l2Categories"] = newSelectedCategories
        campaign['_multiCategorylastUpdatedAt'] = new Date()
        setcampaign({ ...campaign })
    }

    const selectAllL3Handler = () => {
        let newSelectedCategories = areAllL3CategoriesSelected ? campaign.segregatedCategories.l3Categories : filteredL3Categories
        setSelectedL3Categories(newSelectedCategories);
        setAreAllL3CategoriesSelected(prevState => !prevState);
        campaign["segregatedCategories"]["_l3Categories"] = newSelectedCategories
        campaign['_multiCategorylastUpdatedAt'] = new Date()
        setcampaign({ ...campaign })
    }

    return (
        <>
            {/* L1 Categories */}

            {campaign?.isUniversal?.toString() === "false" && (
                <div className="flex flex-col w-full mb-7 last:mb-0">
                    <div className="flex items-center mb-4">
                        <p className="text-xs font-semibold text-[#303030] w-48">Select L1 Category Name ({filteredL1Categories.length}):</p>
                        <div className="w-2/5">
                            <div className="relative">
                                <div className="border border-gray-300 rounded-lg p-3">
                                    <div className="form-field full-width">
                                        <div className='flex items-baseline'>
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                className="mb-2 p-2 border border-gray-300 rounded mr-4"
                                                onChange={(e) => setL1SearchTerm(e.target.value)}
                                            />
                                            <button type="button" className='bg-[#efefe4] border border-solid border-[#c4c4c4] py-1 px-2.5 rounded mr-4' onClick={() => refreshL2Handler(selectedL1Categories)}>Apply</button>
                                            <button type="button" className='bg-[#efefe4] border border-solid border-[#c4c4c4] py-1 px-2.5 rounded' onClick={() => selectAllL1Handler()}>Select All</button>
                                        </div>
                                        <div className="h-32 p-2 border border-gray-300 rounded overflow-auto">
                                            {filteredL1Categories.map((d: any, index: number) => (
                                                <div key={index} className="flex items-center mb-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedL1Categories.some((selected: any) => selected.categoryCode === d.categoryCode)}
                                                        onChange={() => handleCheckboxChangeForL1Category(d)}
                                                        className="mr-2"
                                                    />
                                                    <label>{d.categoryName}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="flex items-center">
                        <p className="text-xs font-semibold text-[#303030] w-48">Selected L1 Categories:</p>
                        <div className="w-full">
                            <div className="relative">
                                <div className="flex flex-wrap mt-2">
                                    {selectedL1Categories.map((category: any, index) => (
                                        <div
                                            key={index}
                                            className="bg-gray-200 text-gray-800 px-2 py-1 m-1 rounded"
                                        >
                                            {category.categoryName}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* L2 Categories */}

            {campaign?.isUniversal?.toString() === "false" && (
                <div className="flex flex-col w-full mb-7 last:mb-0">
                    <div className="flex items-center mb-4">
                        <p className="text-xs font-semibold text-[#303030] w-48">Select L2 Category Name ({filteredL2Categories.length}):</p>
                        <div className="w-2/5">
                            <div className="relative border border-gray-300 rounded-lg p-3">
                                <div className="form-field full-width">
                                    <div className='flex items-baseline'>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="mb-2 p-2 border border-gray-300 rounded mr-4"
                                            onChange={(e) => setL2SearchTerm(e.target.value)}
                                        />
                                        <button type="button" className='bg-[#efefe4] border border-solid border-[#c4c4c4] py-1 px-2.5 rounded mr-4' onClick={() => refreshL3Handler(selectedL2Categories)}>Apply</button>
                                        <button type="button" className='bg-[#efefe4] border border-solid border-[#c4c4c4] py-1 px-2.5 rounded' onClick={() => selectAllL2Handler()}>Select All</button>
                                    </div>
                                    <div className="h-32 p-2 border border-gray-300 rounded overflow-auto">
                                        {filteredL2Categories.map((d: any, index: number) => (
                                            <div key={index} className="flex items-center mb-2">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedL2Categories.some((selected: any) => selected.categoryCode === d.categoryCode)}
                                                    onChange={() => handleCheckboxChangeForL2Category(d)}
                                                    className="mr-2"
                                                />
                                                <label>{d.categoryName}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <p className="text-xs font-semibold text-[#303030] w-48">Selected L2 Categories:</p>
                        <div className="w-full">
                            <div className="relative">
                                <div className="flex flex-wrap mt-2">
                                    {selectedL2Categories.map((category: any, index) => (
                                        <div
                                            key={index}
                                            className="bg-gray-200 text-gray-800 px-2 py-1 m-1 rounded"
                                        >
                                            {category.categoryName}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* L3 Categories */}
            {/* DO NOT SHOW L3 IF CAMPAIGN_TYPE === "PRODUCT_BASED_CAMPAIGN" */}
            {(campaign?.campaignType !== CAMPAIGN_TYPES.PRODUCT_BASED_CAMPAIGN) && (campaign?.isUniversal?.toString() === "false") && (
                <div className="flex flex-col w-full mb-7 last:mb-0">
                    <div className="flex items-center mb-4">
                        <p className="text-xs font-semibold text-[#303030] w-48">Select L3 Category Name ({filteredL3Categories.length}):</p>
                        <div className="w-2/5">
                            <div className="relative border border-gray-300 rounded-lg p-3">
                                <div className="form-field full-width">
                                    <div className='flex items-baseline'>
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            className="mb-2 p-2 mr-4 border border-gray-300 rounded"
                                            onChange={(e) => setL3SearchTerm(e.target.value)}
                                        />
                                        <button type="button" className='bg-[#efefe4] border border-solid border-[#c4c4c4] py-1 px-2.5 rounded' onClick={() => selectAllL3Handler()}>Select All</button>
                                    </div>
                                    <div className="h-32 p-2 border border-gray-300 rounded overflow-auto">
                                        {filteredL3Categories.map((d: any, index: number) => (
                                            <div key={index} className="flex items-center mb-2">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedL3Categories.some((selected: any) => selected.categoryCode === d.categoryCode)}
                                                    onChange={() => handleCheckboxChangeForL3Category(d)}
                                                    className="mr-2"
                                                />
                                                <label>{d.categoryName}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <p className="text-xs font-semibold text-[#303030] w-48">Selected L3 Categories:</p>
                        <div className="w-full">
                            <div className="relative">
                                <div className="flex flex-wrap mt-2">
                                    {selectedL3Categories.map((category: any, index) => (
                                        <div
                                            key={index}
                                            className="bg-gray-200 text-gray-800 px-2 py-1 m-1 rounded"
                                        >
                                            {category.categoryName}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div >)}
        </>
    )
}

export default MultiCategorySelection