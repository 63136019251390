import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.scss';
import IMAGES from '../../../assets/img/image';
import { isAuthorizedUser } from '../../../utils/helper';
import { useSelector } from 'react-redux';
import { USER_ROLES } from '../../../utils/constants';

const navigationMap: any = {
    BLOCK: "/block",
    SEO: "/seo",
    PAGE_LIST: "/layout",
    MANAGE_CAMPAIGN: "/campaign",
    WHATSAPP_CAMPAIGN: "/whatsappCampaign",
    AI_POWERED: "/aiPowered",
    COUPON: "/coupon",
    USER_MANAGEMENT: "/userManagement",
    QUESTION_ANSWER: "/questionAnswer",
    CLICK_STREAM: "/clickStream",
    PRODUCT_TAGS: "/productTagging",
    BLOCK_CUSTOMER: "/blockCustomer",
    SERVICEABILITY: "/serviceability",
    FBT: "/fbt",
    BLOCKS: "/blocks/brand",
    FAQ_SCHEMA: "/faq",
    REVIEWS_MODERATION: "/review",
    ARTICLE: "/article",
    PLA_FEED: "/plaFeed",
    CACHE_DELETION: "/cacheDelete",
    PAGE_MAPPING: "/pageMapping",
    ATTRIBUTE_LISTING: "/attributeListing",
    MEXPRESS: "/mexpress"
};

const SideNavbar = () => {
    const { user } = useSelector((s: any) => s.authStore)
    const navigate = useNavigate()

    const navigationHandler = (type: string) => {
        const path = navigationMap[type];
        if (path) {
            navigate(path);
        }
    };

    return (
        <div className='leftsidewrap'>
            <div className='bg-[#43425d] h-full'>
                <div className='moglogo'>
                    <img src={IMAGES.LOGO.darklogo} alt="" className='img-responsive' />
                </div>
                <ul className='leftsidemenu'>
                    <li onClick={() => navigationHandler("PAGE_LIST")} className='blocksList'>
                        <a>CMS Layout</a>
                    </li>
                    <li onClick={() => navigationHandler("BLOCKS")} className='blocksList'>
                        <a>Blocks</a>
                    </li>
                    <li onClick={() => navigationHandler("MANAGE_CAMPAIGN")} className='blocksList'>
                        <a>Adsense Campaign</a>
                    </li>
                    <li onClick={() => navigationHandler("QUESTION_ANSWER")} className='blocksList'>
                        <a>Q & A</a>
                    </li>
                    {/* <li onClick={() => navigationHandler("CLICK_STREAM")} className='blocksList'>
                        <a>Click Stream</a>
                    </li> */}
                    <li onClick={() => navigationHandler("PRODUCT_TAGS")} className='blocksList'>
                        <a>Product Tags</a>
                    </li>
                    {isAuthorizedUser(user?.roleList, USER_ROLES.MASTER_ADMIN) &&

                        <li onClick={() => navigationHandler("WHATSAPP_CAMPAIGN")} className='blocksList'>
                            <a>Whatsapp Campaign</a>
                        </li>}

                    {isAuthorizedUser(user?.roleList, USER_ROLES.MASTER_ADMIN) &&

                        <li onClick={() => navigationHandler("AI_POWERED")} className='blocksList'>
                            <a>AI Powered</a>
                        </li>}
                    {isAuthorizedUser(user?.roleList, USER_ROLES.MASTER_ADMIN) &&

                        <li onClick={() => navigationHandler("REVIEWS_MODERATION")} className='blocksList'>
                            <a>Reviews Moderation</a>
                        </li>}
                    <li onClick={() => navigationHandler("ARTICLE")} className='blocksList'>
                        <a>Article</a>
                    </li>
                    <li onClick={() => navigationHandler("BLOCK_CUSTOMER")} className='blocksList'>
                        <a>Block Customer</a>
                    </li>
                    <li onClick={() => navigationHandler("SERVICEABILITY")} className='blocksList'>
                        <a>Serviceability</a>
                    </li>
                    <li onClick={() => navigationHandler("FBT")} className='blocksList'>
                        <a>FBT</a>
                    </li>
                    <li onClick={() => navigationHandler("FAQ_SCHEMA")} className='blocksList'>
                        <a>FAQ</a>
                    </li>
                    <li onClick={() => navigationHandler("PLA_FEED")} className='blocksList'>
                        <a>PLA Feed</a>
                    </li>
                    <li onClick={() => navigationHandler("CACHE_DELETION")} className='blocksList'>
                        <a>Cache Deletion</a>
                    </li>
                    <li onClick={() => navigationHandler("PAGE_MAPPING")} className='blocksList'>
                        <a>Page Mapping</a>
                    </li>
                    <li onClick={() => navigationHandler("ATTRIBUTE_LISTING")} className='blocksList'>
                        <a>Attribute Listing</a>
                    </li>
                    {/* <li onClick={() => navigationHandler("USER_MANAGEMENT")} className='blocksList'>
                        <a>User Management</a>
                    </li> */}
                    <li onClick={() => navigationHandler("PAGE_MAPPING")} className='blocksList'>
                        <a>Page Mapping</a>
                    </li>
                    <li onClick={() => navigationHandler("MEXPRESS")} className='blocksList'>
                        <a>MExpress</a>
                    </li>
                </ul >
            </div >
        </div >
    )
}

export default SideNavbar;
